import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import {
  Container,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Button,
} from "reactstrap"
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3"
import { toast } from "react-toastify"

import { contentOptions } from "../../utils/parser"

import "./index.scss"

const BestellformularTab = ({
  allContentfulHofladenBestellformularTexte,
  allContentfulHofladenArtikelBestellformular,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  function submitForm(ev) {
    if (!executeRecaptcha) {
    } else {
      ev.preventDefault()
      const form = ev.target
      const data = new FormData(form)
      const xhr = new XMLHttpRequest()
      for (
        let i = 0;
        i < allContentfulHofladenArtikelBestellformular.edges.length;
        i++
      ) {
        const currentValue = data.get(
          allContentfulHofladenArtikelBestellformular.edges[i].node.name
        )
        if (!currentValue) {
          data.delete(
            allContentfulHofladenArtikelBestellformular.edges[i].node.name
          )
        }
      }

      xhr.open(form.method, form.action)
      xhr.setRequestHeader("Accept", "application/json")
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return
        if (xhr.status === 200) {
          form.reset()
          toast(
            "Ihre Anfrage wurde erfolgreich gesendet. Wir melden uns bei Ihnen",
            { type: "success" }
          )
        } else {
          toast(
            "Wir haben einen Fehler entdeckt. Bitte versuchen Sie es noch einmal",
            { type: "error" }
          )
        }
      }
      xhr.send(data)
    }
  }

  return (
    <Container>
      <Row>
        <Col md="12" lg="12">
          <section className="about-content">
            {" "}
            {allContentfulHofladenBestellformularTexte?.edges?.[0]?.node
              ?.intro && (
              <h2>
                {" "}
                {
                  allContentfulHofladenBestellformularTexte.edges[0].node.intro
                }{" "}
              </h2>
            )}
            {allContentfulHofladenBestellformularTexte?.edges?.[0].node
              ?.introBeschreib?.raw &&
              documentToReactComponents(
                JSON.parse(
                  allContentfulHofladenBestellformularTexte.edges[0].node
                    .introBeschreib.raw
                ),
                contentOptions
              )}
            {/* {allContentfulHofladenBestellformularTexte?.edges?.[0].node?.titel2?.raw &&
                          documentToReactComponents(JSON.parse(allContentfulHofladenBestellformularTexte.edges[0].node.titel2.raw), contentOptions)}

                        {allContentfulHofladenBestellformularTexte?.edges?.[0].node?.beschreib2?.raw &&
                          documentToReactComponents(JSON.parse(allContentfulHofladenBestellformularTexte.edges[0].node.beschreib2.raw), contentOptions)} */}
          </section>
        </Col>
      </Row>
      
      {/* <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_KEY}>
        <Row className="contact-form-block">
          <Col>
          <h2>Bestellformular
              {allContentfulHofladenBestellformularTexte?.edges?.[0]?.node?.pdf?.file?.url && (
                <a className="pdf-url" rel="noreferrer" target="_blank" href={"https://" + allContentfulHofladenBestellformularTexte.edges[0].node.pdf.file.url}>PDF</a>
              )}
            </h2>
            {" "}
            <Form
              method="POST"
              onSubmit={submitForm}
              // action={process.env.GATSBY_FORMSPREE_CONTACT_ENDPOINT}
              action="https://formspree.io/f/xqkwjadq"
            >
              <Row>
                <Col xs={5} lg={6} sm={6}>
                  <div className="sub sub1"> Artikel </div>{" "}
                </Col>
                <Col xs={4} lg={3} sm={4}>
                  <div className="sub"> Preis in Fr. </div>
                </Col>{" "}
                <Col xs={3} lg={2} sm={2}>
                  <div className="sub"> Anzahl </div>
                </Col>{" "}
              </Row>{" "}
              {allContentfulHofladenArtikelBestellformular?.edges?.map(
                (artikel, index) => {
                  return (
                    artikel?.node?.show && (
                      <>
                        <Row style={{ margin: "10px -15px" }}>
                          <Col className="desc1" xs={5} lg={6} sm={6}>
                            <div style={{ fontWeight: "normal" }}>
                              {" "}
                              {artikel?.node?.name}{" "}
                            </div>
                            <div className="desktop-description">
                              {" "}
                              {allContentfulHofladenArtikelBestellformular
                                ?.edges?.[index].node?.description?.raw &&
                                documentToReactComponents(
                                  JSON.parse(
                                    allContentfulHofladenArtikelBestellformular
                                      ?.edges[index].node.description.raw
                                  ),
                                  contentOptions
                                )}{" "}
                            </div>{" "}
                          </Col>{" "}
                          <Col className="desc" xs={4} lg={3} sm={4}>
                            {" "}
                            {artikel?.node?.price}{" "}
                          </Col>{" "}
                          <Col className="desc" xs={3} lg={2} sm={2}>
                            <Input
                              type="number"
                              name={artikel.node.name}
                              id={artikel.node.name}
                              placeholder="0"
                              className="nummer"
                            />
                          </Col>{" "}
                        </Row>{" "}
                        <Row className="mobile-description">
                          <Col xs={12}>
                            <div>
                              {" "}
                              {allContentfulHofladenArtikelBestellformular
                                ?.edges?.[index].node?.description?.raw &&
                                documentToReactComponents(
                                  JSON.parse(
                                    allContentfulHofladenArtikelBestellformular
                                      ?.edges[index].node.description.raw
                                  ),
                                  contentOptions
                                )}{" "}
                            </div>{" "}
                          </Col>{" "}
                        </Row>{" "}
                      </>
                    )
                  )
                }
              )}{" "}
              <br />
              <FormGroup>
                <Label className="label" for="lehrperson">
                  {" "}
                  Name <span className="required"> * </span>
                </Label>
                <Input
                  required
                  type="text"
                  name="name"
                  id="Name"
                  placeholder="Name"
                />
              </FormGroup>
              <FormGroup>
                <Label className="label" for="email">
                  {" "}
                  E - Mail <span className="required"> * </span>
                </Label>
                <Input
                  required
                  type="email"
                  name="email"
                  id="email"
                  placeholder="E-Mail"
                />
              </FormGroup>
              <FormGroup>
                <Label className="label" for="phone">
                  {" "}
                  Telefon Nr. <span className="required"> * </span>
                </Label>
                <Input
                  required
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Telefon Nr."
                />
              </FormGroup>
              <FormGroup>
                <Label className="label" for="nachricht">
                  {" "}
                  Nachricht{" "}
                </Label>{" "}
                <Input
                  type="textarea"
                  name="nachricht"
                  id="nachricht"
                  placeholder="Nachricht"
                />
              </FormGroup>{" "}
              <Button style={{ backgroundColor: "#3D804CC0" }}> Senden </Button>{" "}
            </Form>{" "}
          </Col>{" "}
        </Row>{" "}
        <br />
      </GoogleReCaptchaProvider>{" "} */}
    </Container>
  )
}

export default BestellformularTab
